<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="opencreate()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-btn
        absolute
        top
        right
        fab
        small
        style="margin-right:66px;"
        @click="openautocreate()"
      >
        <v-icon>
          fa-layer-plus
        </v-icon>
      </v-btn>

      <v-btn
        absolute
        top
        right
        fab
        small
        style="margin-right:118px;"
        @click="downloadcsv()"
      >
        <v-icon>
          fa-download
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>
      <v-col>
        <v-range-slider
          v-model="date"
          :tick-labels="dates_format"
          :value="dates"
          min="0"
          :max="dates.length - 1"
          ticks="always"
          tick-size="4"
        >
        </v-range-slider>
      </v-col>

      <EventTable
        :events="events_filtered"
        :filter="filter"
        admin
        :open="false"
        @openteams="(id) => openteams(id)"
        @changestatus="({id, value}) => changestatus(id, value)"
        @changespectators="({id, value}) => changespectators(id, value)"
        @set12="({id, tid}) => set12(id, tid)"
        @edit="(id) => open(id)"
        @input="(id, ev) => input(id, ev)"
        @view="(id, ev) => view(id, ev)"
        @del="(id) => del(id)"
      />
    </v-card>

    <base-material-dialog
      title="Neue Begegnung anlegen"
      :value="create.open"
      icon="far fa-calendar"
      color="primary"
      :actions="[ 'save', 'cancel' ]"
      @close="closecreate"
      @esc="closecreate"
      @save="savecreate"
    >
      <v-card flat>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="create.liga"
              :items="leagues"
              label="Liga"
              item-value="_id"
              item-text="name"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="create.mode"
              :items="modes"
              label="Modus"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="create.name"
              label="Name"
            />
          </v-col>
          <v-col cols="6">
            <date-time-selector
              v-model="create.startDate"
              label="Beginn"
            />
          </v-col>
        </v-row>
      </v-card>
    </base-material-dialog>

    <base-material-dialog
      title="Neue Begegnungen automatisch anlegen"
      :value="autocreate.open"
      icon="far fa-calendar"
      color="primary"
      :actions="[ 'save', 'cancel' ]"
      @close="closeautocreate"
      @esc="closeautocreate"
      @save="saveautocreate"
    >
      <v-card flat>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="autocreate.liga"
              :items="leagues"
              label="Liga"
              item-value="_id"
              item-text="name"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="autocreate.mode"
              :items="modes"
              label="Modus"
            />
          </v-col>
        </v-row>
      </v-card>
    </base-material-dialog>

    <base-material-dialog
      title="Mannschaften bearbeiten"
      :value="teams.open"
      icon="far fa-calendar"
      color="primary"
      :actions="[ 'close' ]"
      @close="closeteams"
      @esc="closeteams"
    >
      <v-data-table
        :items="ligawkteams"
        :headers="[
          { text: 'Mannschaft', value: 'name', sortable: false },
          { text: 'Reihenfolge', value: 'order', sortable: false },
          { text: 'Heimmannschaft', value: 'home', sortable: false, align: 'center' },
          { text: 'Hinzufügen', value: 'add', sortable: false, align: 'center' },
          { text: 'Entfernen', value: 'del', sortable: false, align: 'center' }
        ]"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item.name="{item}">
          <span :class="{active:item.active}">{{ item.name }}</span>
        </template>
        <template #item.home="{item}">
          <v-btn
            v-if="item.home !== undefined"
            text
            fab
            small
            @click="changehome(item)"
          >
            {{ item.home ? 'Ja' : 'Nein' }}
          </v-btn>
        </template>
        <template #item.add="{item}">
          <v-btn
            v-if="!item.active"
            fab
            x-small
            @click="addteam(item._team)"
          >
            <v-icon>far fa-plus</v-icon>
          </v-btn>
        </template>
        <template #item.del="{item}">
          <v-btn
            v-if="item.active"
            fab
            x-small
            @click="delteam(item._team)"
          >
            <v-icon>far fa-minus</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </base-material-dialog>
    <edit-event
      v-model="editdialog.open"
      :id="editdialog.id"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'
import { StbM2021WKMode } from '@/enum'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbM2021League {
    name
    shortName
    order
    teams {
      team { _id name }
      order
    }
    events {
      _id
      name
      startDate
      publicComment
      status
      mode
      teams {
        team { _id name club { _id name } }
        order home neutralwaiver
        result { final gp tp score }
      }
      venue { _id name address { streetAddress streetNumber postalCode addressLocality } }
      matchday { _id name startDate }
      judges { person { _id familyName givenName } _discipline type club { name } }
      info {
        attachments { _id filename mimetype }
        zuschauer
      }
    }
  }
`

export default {
  name: 'events',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  components: {
    EditEvent: () => import('../dialogs/EditEvent'),
    EventTable: () => import('../../components/EventTable')
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    filter: '',
    date: [0, 0],
    create: {
      open: false,
      liga: null,
      startDate: null,
      mode: null,
      name: ''
    },
    autocreate: {
      open: false,
      liga: null,
      mode: null
    },
    teams: {
      open: false,
      id: null
    },
    editdialog: {
      open: false,
      id: null
    }
  }),

  computed: {
    modes () {
      return StbM2021WKMode
    },
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...(curr.events || []).map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || [],
          ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || []
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    },
    events_filtered () {
      const start = this.dates[this.date[0]] + ' 00:00:00'
      const end = this.dates[this.date[1]] + ' 23:59:39'

      return this.events.filter(e => e.startDate >= start && e.startDate <= end)
    },
    leagues () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push({
          _id: curr._id,
          name: curr.name,
          order: curr.order
        })
        return acc
      }, []).sort((a, b) => a.order < b.order ? -1 : 1)
    },
    event () {
      if (!this.teams.id) return {}
      return this.events?.find(e => e._id === this.teams.id)
    },
    ligateams () {
      return this.event?.liga?.teams || []
    },
    wkteams () {
      return this.event?.teams || []
    },
    ligawkteams () {
      return this.ligateams.map(t => ({
        _team: t.team._id,
        name: t.team.name,
        ligaorder: t.order,
        active: !!this.wkteams.find(wkt => wkt.team._id === t.team._id),
        order: this.wkteams.find(wkt => wkt.team._id === t.team._id)?.order,
        home: this.wkteams.find(wkt => wkt.team._id === t.team._id)?.home
      }))
    },
    dates () {
      if (this.events.length === 0) return ['']
      return this.events.map(e => e.startDate?.split(' ')?.[0]).sort().filter((d, i, arr) => !arr.find((d2, j) => j < i && d === d2))
    },
    dates_format () {
      return this.dates.map(d => this.$options.filters.dateformat(d, 'DD.MM.'))
    }
  },

  methods: {
    opencreate () {
      this.create.open = true
      this.create.liga = null
      this.create.startDate = null
      this.create.mode = null
      this.create.name = null
    },
    closecreate () {
      this.create.open = false
      this.create.liga = null
      this.create.startDate = null
      this.create.mode = null
      this.create.name = null
    },
    savecreate () {
      if (this.create.liga && this.create.startDate && this.create.mode) {
        this.mutate({
          mutation: gql`mutation($parent: UUID!, $name: String!, $startDate: DateTime!, $mode: StbM2021WkMode!) {
            StbM2021WkCreate(parent: $parent, name: $name, startDate: $startDate, mode: $mode) {
              _id
            }
          }`,
          variables: {
            parent: this.create.liga,
            name: this.create.name,
            startDate: this.create.startDate,
            mode: this.create.mode
          }
        })

        this.closecreate()
      }
    },
    openautocreate () {
      this.autocreate.open = true
      this.autocreate.liga = null
      this.autocreate.mode = null
    },
    closeautocreate () {
      this.autocreate.open = false
      this.autocreate.liga = null
      this.autocreate.mode = null
    },
    saveautocreate () {
      if (this.autocreate.liga && this.autocreate.mode) {
        this.mutate({
          mutation: gql`mutation($parent: UUID!, $mode: StbM2021WkMode!) {
            StbM2021WkAutocreate(parent: $parent, mode: $mode)
          }`,
          variables: {
            parent: this.autocreate.liga,
            mode: this.autocreate.mode
          }
        })

        this.closeautocreate()
      }
    },
    del (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!) {
          StbM2021WkDelete(id: $id)
        }`,
        variables: {
          id
        }
      })
    },
    openteams (id) {
      this.teams.open = true
      this.teams.id = id
    },
    closeteams () {
      this.teams.open = false
      this.teams.id = null
    },
    addteam (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!) {
            StbM2021WkTeamAdd(id: $id, team: $team) { _id }
          }
        `,
        variables: {
          id: this.teams.id,
          team: id
        }
      })
    },
    delteam (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!) {
            StbM2021WkTeamDelete(id: $id, team: $team) { _id }
          }
        `,
        variables: {
          id: this.teams.id,
          team: id
        }
      })
    },
    input (id, ev) {
      this.$openlink(`/input/${id}`, ev)
    },
    view (id, ev) {
      this.$openlink(`/view/${id}`, ev)
    },
    changehome (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!, $home: Boolean!) {
            StbM2021WkTeamUpdateHome(id: $id, team: $team, home: $home) { _id }
          }
        `,
        variables: {
          id: this.teams.id,
          team: item._team,
          home: !item.home
        }
      })
    },
    changestatus (id, status) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $status: EventStatus!) {
            StbM2021WkUpdateStatus(id: $id, status: $status) { _id }
          }
        `,
        variables: {
          id,
          status
        }
      })
    },
    changespectators (id, zuschauer) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $zuschauer: Int!) {
            StbM2021WkInfoUpdate(id: $id, zuschauer: $zuschauer) { _id }
          }
        `,
        variables: {
          id,
          zuschauer: parseInt(zuschauer)
        }
      })
    },
    set12 (id, tid) {
      const e = this.events.find(e => e._id === id)
      const isScore = e.mode === 'wk_score4'
      e.teams.forEach(t => {
        const team = t.team._id
        const final = t.result?.final || 0
        const score = isScore ? t.result?.score || 0 : null
        const gp = team === tid ? 12 : 0
        const tp = team === tid ? 2 : 0

        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!,
              $final: Float, $score: Int, $gp: Float, $tp: Float
              ) {
                StbM2021WkTeamUpdateResult(
                  id: $id, team: $team,
                  final: $final, score: $score, gp: $gp, tp: $tp
                ) { _id }
            }
          `,
          variables: {
            id,
            team,
            final,
            score,
            gp,
            tp
          }
        })
      })

      this.changestatus(id, 'EventJudged')
    },
    open (id) {
      this.editdialog.id = id
      this.editdialog.open = true
    },
    judge (e, type) {
      const tmp = e.judges?.find(j => j.type === type && !j.discipline)?.person
      return tmp ? this.$options.filters.person(tmp) : ''
    },
    judgeclub (e, type) {
      return e.judges?.find(j => j.type === type && !j.discipline)?.club?.name || ''
    },
    downloadcsv () {
      const tmp = this.events.slice().sort((a, b) => a.startDate < b.startDate ? -1 : 1).filter(e => !e.mode.match(/^l[pf]_/)).map(e => {
        const h = e.teams?.find(t => t.home)
        const g = e.teams?.find(t => !t.home)
        return `${e._id};${e.startDate};${e.liga.name};${e.liga.shortName};${e.matchday?.name};${h.team?.club?.name};${h.team?.name};${h.neutralwaiver};${g.team?.club?.name};${g.team?.name};${g.neutralwaiver};${h.neutralwaiver && g.neutralwaiver};${e.venue?.name};${this.judge(e, 'e1')};${this.judgeclub(e, 'e1')};${this.judge(e, 'e2')};${this.judgeclub(e, 'e2')};${this.judge(e, 'dh')};${this.judge(e, 'dg')};${this.judge(e, 'e3')};${this.judge(e, 'e4')};${e.status}`
      })

      const csv = `ID;Beginn;Liga;Ligakürzel;Wettkampftag;Heimverein;Heimmannschaft;Neutralverzicht Heim;Gastverein;Gastmannschaft;Neutralverzicht Gast;Neutralverzicht gesamt;Halle;OK;OK Verein;neutraler Kari;neutraler Kari Verein;Heim-Ausgang;Gast-Ausgang;Heim-Abzug;Gast-Abzug;Status\n${tmp.join('\n')}`

      const saveByteArray = (fileName, bytes) => {
        const blob = new Blob(['\ufeff', bytes], { type: 'application/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      }

      saveByteArray('WKs.csv', csv)
    }
  },

  watch: {
    dates () {
      if (this.date[0] !== 0 || this.date[1] !== 0) return

      const now = (new Date()).toISOString().split('T')[0]

      const index = this.dates.findIndex(d => d >= now)
      if (index !== -1) {
        if (this.dates[index] === now) {
          this.date = [index, this.dates.length - 1]
        } else {
          this.date = [index - 1, this.dates.length - 1]
        }
      }
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>
.active {
  font-weight: bold;
  color: #4caf50;
}
</style>
